<template>
  <v-container class="place-container">
    <!-- start spiner --->
    <v-row align="center" class="pt-10" justify="center" v-if="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <!-- end -->
    <VenueTemplate :venue-data="venueData"
      @show-prev-venue="$emit('show-prev-venue')"
      @show-next-venue="$emit('show-next-venue')"
    />
  </v-container>
</template>

<script>
import VenueTemplate from '@/pages/Venues/VenueTemplate'

import { mapState } from 'vuex'
export default {
  name: 'VenuePlaceDetail',
  components: {
    VenueTemplate
  },
  async created () {
    try {
      await this.getPlaceInfo()
      // let phone = this.selectedPlace.phone || ''
      // console.log('this.selectedPlace.contacts')
      // console.log(this.selectedPlace.contacts)
      // if (this.selectedPlace.contacts && Array.isArray(this.selectedPlace.contacts) &&
      // this.selectedPlace.contacts.length) phone = this.selectedPlace.contacts[0].phone
      this.$store.commit('tempStates/SET_FLOATING_ACTION_BUTTONS',
        [
          {
            key: 'planner',
            value: ''
          },
          {
            key: 'message',
            value: ''
          },
          {
            key: 'share',
            value: ''
          },
          {
            key: 'customize-filters',
            value: ''
          }
        ]
      )
    } catch (error) {
      console.error(error)
    }
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      selectedPlace: (state) => state.places.selectedPlace,
      selectedPlaceProducts: (state) => state.places.selectedPlaceProducts,
      selectedPlaceCategoriesProducts: (state) => state.places.selectedPlaceCategoriesProducts,
      selectedPlaceId: (state) => state.storedStates.selectedPlaceId
    }),
    venueData () {
      return {
        ...this.selectedPlace,
        venueDataProducts: this.selectedPlaceProducts,
        venueDataPlaceCategoriesProducts: this.selectedPlaceCategoriesProducts
      }
    }
  },
  methods: {
    async getPlaceInfo () {
      this.$store.commit('storedStates/SET_SELECTED_VENUE', 'Place')
      const { id } = this.$route.params
      if (id !== this.selectedPlaceId) {
        console.log('id not equals to selectedPlaceId')
        console.log('id')
        console.log(id)
        console.log('this.selectedPlaceId')
        console.log(this.selectedPlaceId)
        await this.$store.commit('storedStates/SET_SELECTED_PLACE_ID', id)
      }
      try {
        await this.$store
          .dispatch('places/getPlace', this.selectedPlaceId)
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
      }
      try {
        await this.$store
          .dispatch('places/getSelectedPlaceCategoriesProducts')
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    async selectedPlaceId (val) {
      await this.getPlaceInfo()
    }
  }
}
</script>

<style scoped>
.place-container {
  padding-top: 0;
}
</style>
